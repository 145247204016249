import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
import { useFetchData } from '../graphql/propertyFetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** An organization registered in the County's system */
export type Account = {
  businessAddress: Address;
  businessDescription?: Maybe<Scalars['String']>;
  employerIdentificationNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isHomeBusiness?: Maybe<Scalars['Boolean']>;
  naicsCode?: Maybe<Scalars['String']>;
  otherContacts: Array<NonPrimaryContact>;
  primaryContact?: Maybe<PrimaryContact>;
  registeredEntityName?: Maybe<Scalars['String']>;
  registeredEntityType?: Maybe<EntityType>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearOpened?: Maybe<Scalars['Int']>;
};

export type AccountInput = {
  businessDescription?: InputMaybe<Scalars['String']>;
  employerIdentificationNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isHomeBusiness: Scalars['Boolean'];
  jurisdiction: Scalars['Int'];
  naicsCode: Scalars['String'];
  otherContacts?: InputMaybe<Array<InputMaybe<NonPrimaryContactInput>>>;
  primaryContact: PrimaryContactInput;
  registeredEntityName?: InputMaybe<Scalars['String']>;
  registeredEntityType?: InputMaybe<EntityTypeInput>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  yearOpened?: InputMaybe<Scalars['Int']>;
};

/** A location identified by street, city, and state */
export type Address = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  inCareOf?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<CountryInput>;
  inCareOf?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressOtherContactInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryInput>;
  inCareOf?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type Asset = {
  classification?: Maybe<Scalars['String']>;
  classificationDescription?: Maybe<Scalars['String']>;
  costPer?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  percentGood?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  taxableValue?: Maybe<Scalars['Int']>;
  yearAcquired?: Maybe<Scalars['Int']>;
};

export type AssetInput = {
  classification: Scalars['String'];
  costPer: Scalars['Int'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isUpdated?: InputMaybe<Scalars['Boolean']>;
  percentGood: Scalars['Float'];
  quantity: Scalars['Int'];
  taxableValue: Scalars['Int'];
  yearAcquired: Scalars['Int'];
};

export type AssociatedAccount = {
  accountId?: Maybe<Scalars['Int']>;
  accountNumber: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isReconciled?: Maybe<Scalars['Boolean']>;
};

export type AssociatedAccountGroup = {
  accounts: Array<AssociatedAccount>;
  id: Scalars['Int'];
};

export type AssociatedAccountGroupInput = {
  accountId: Scalars['Int'];
  accounts: Array<AssociatedAccountInput>;
  id: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};

export type AssociatedAccountInput = {
  accountId?: InputMaybe<Scalars['Int']>;
  accountNumber: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isUpdated?: InputMaybe<Scalars['Boolean']>;
};

/** Provides the token for all the requests */
export type Auth = {
  token: Scalars['String'];
};

export type BasicAddress = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type BasicCost = {
  baseYear?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  percentGood?: Maybe<Scalars['Float']>;
  propertyYear?: Maybe<Scalars['Int']>;
  scheduleId?: Maybe<Scalars['Int']>;
};

export type BusinessAccount = {
  accountId: Scalars['Int'];
  accountNumber: Scalars['String'];
  address: Address;
  businessName: Scalars['String'];
  filingStatus?: Maybe<Scalars['String']>;
  hasLinkedAccounts: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isExempt: Scalars['Boolean'];
  isReconciled: Scalars['Boolean'];
  jurisdiction: Jurisdiction;
  linkedAccounts: Array<AssociatedAccount>;
  linkedAccountsReconciled: Scalars['Boolean'];
  taxArea: Scalars['String'];
  taxDue: Scalars['Float'];
  taxYear: Scalars['Int'];
  taxableValue: Scalars['Float'];
};

export type CalculatePercentGood = {
  percentGood: Scalars['Float'];
};

export type Classification = {
  basicCost?: Maybe<Array<BasicCost>>;
  clsf?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  scheduleId?: Maybe<Scalars['Int']>;
};


export type ClassificationBasicCostArgs = {
  taxYear?: InputMaybe<Scalars['Int']>;
};

/** Point of Contact that can recieve messages from the County */
export type Contact = {
  cellphone?: Maybe<Scalars['String']>;
  contactType?: Maybe<ContactType>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  sameAsBusinessAddress: Scalars['Boolean'];
};

/** Identifies the relationship between the Contact and the Account */
export type ContactType = {
  id: Scalars['ID'];
  /** Human-readable label used to display in the application */
  label: Scalars['String'];
};

export type ContactTypeInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type Country = {
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type CountryInput = {
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type CountyContact = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CountySupport = {
  address?: Maybe<Scalars['String']>;
  contacts: Array<CountyContact>;
  countyName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  supportUrl?: Maybe<Scalars['String']>;
  workDays?: Maybe<Scalars['String']>;
  workHours?: Maybe<Scalars['String']>;
};

export type DeletedLessor = {
  contactLinkId: Scalars['ID'];
};

/** Identify the type of Business Entity this Account belongs to */
export type EntityType = {
  id: Scalars['ID'];
  /** Human-readable label used to display in the application */
  label: Scalars['String'];
};

export type EntityTypeInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ExperienceSurveyInput = {
  message?: InputMaybe<Scalars['String']>;
  timeSpentFiling: Scalars['String'];
};

export type FilingSummary = {
  accountId: Scalars['Int'];
  addedAssets: SummaryAsset;
  amountDue: Scalars['Float'];
  changedAssets: SummaryAsset;
  contacts: Array<SummaryContact>;
  delinquentTax: Scalars['Float'];
  interest: Scalars['Float'];
  jurisdiction: Scalars['Int'];
  leaseReportFileUrl?: Maybe<Scalars['String']>;
  netTotalCost: Scalars['Float'];
  netTotalValue: Scalars['Float'];
  nonLeaseReportFileUrl?: Maybe<Scalars['String']>;
  otherChargesCredits: Scalars['Float'];
  payments: Scalars['Float'];
  penalty: Scalars['Float'];
  previousYearAssets: SummaryAsset;
  removedAssets: SummaryAsset;
  taxRate: TaxRate;
  taxYear: Scalars['Int'];
  taxableDue: Scalars['Float'];
  totalTaxableValue: Scalars['Float'];
};

export type FilingSummaryInput = {
  accountId: Scalars['Int'];
  contact: SummaryContactInput;
  experienceSurvey: ExperienceSurveyInput;
  jurisdiction: Scalars['Int'];
  pin?: InputMaybe<Scalars['String']>;
};

export type Jurisdiction = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LeasedAsset = {
  annualLeaseAmount?: Maybe<Scalars['Float']>;
  assetId: Scalars['ID'];
  blobName?: Maybe<Scalars['String']>;
  contactLinkId?: Maybe<Scalars['ID']>;
  costPer?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  initialValue?: Maybe<Scalars['Float']>;
  leaseAccountId?: Maybe<Scalars['ID']>;
  leaseEndDate?: Maybe<Scalars['String']>;
  leaseId?: Maybe<Scalars['ID']>;
  leaseNumber?: Maybe<Scalars['ID']>;
  leaseStartDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  yearAcquired?: Maybe<Scalars['Int']>;
};

export type LeasedAssetInput = {
  annualLeaseAmount?: InputMaybe<Scalars['Float']>;
  assetId: Scalars['ID'];
  blobName?: InputMaybe<Scalars['String']>;
  contactLinkId: Scalars['ID'];
  costPer: Scalars['Int'];
  description: Scalars['String'];
  fileUrl?: InputMaybe<Scalars['String']>;
  initialValue?: InputMaybe<Scalars['Float']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isUpdated?: InputMaybe<Scalars['Boolean']>;
  leaseAccountId: Scalars['ID'];
  leaseEndDate?: InputMaybe<Scalars['String']>;
  leaseId: Scalars['ID'];
  leaseNumber?: InputMaybe<Scalars['ID']>;
  leaseStartDate?: InputMaybe<Scalars['String']>;
  prevBlobName?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  yearAcquired: Scalars['Int'];
};

export type Lessor = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  assets: Array<LeasedAsset>;
  careOf?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactLinkId?: Maybe<Scalars['ID']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state?: Maybe<State>;
  zipcode?: Maybe<Scalars['String']>;
};

export type LessorInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  assets: Array<LeasedAssetInput>;
  city?: InputMaybe<Scalars['String']>;
  contactLinkId: Scalars['ID'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isUpdated?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<State>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  authByEmailCode: Auth;
  calculatePercentGood: CalculatePercentGood;
  changePassword: Auth;
  confirmAssets: Scalars['Boolean'];
  createUserInformation: Scalars['Boolean'];
  deleteBusinessAccountLink: Scalars['Boolean'];
  deleteLessor?: Maybe<DeletedLessor>;
  forgotPassword: Scalars['Boolean'];
  generatePaymentVoucher: PaymentVoucher;
  generatePresignedUploadFile: SasUploadFile;
  linkBusinessAccountToUser: Scalars['Boolean'];
  login: Auth;
  reopenFiling?: Maybe<Scalars['Boolean']>;
  requestEmailLogin: Scalars['Boolean'];
  resendVerifyEmail: Scalars['Boolean'];
  resetPassword: Auth;
  saveAccount?: Maybe<Account>;
  saveAssets: Array<Asset>;
  saveAssociatedAccounts: AssociatedAccountGroup;
  saveLessor: Lessor;
  skipAssociatedAccounts: Scalars['Boolean'];
  skipLeasedAssets: Scalars['Boolean'];
  submitFiling?: Maybe<Scalars['Boolean']>;
  updateProfile: Scalars['Boolean'];
  verifyEmail: Auth;
};


export type MutationAuthByEmailCodeArgs = {
  encryptedValue: Scalars['String'];
};


export type MutationCalculatePercentGoodArgs = {
  classification: Scalars['String'];
  jurisdiction: Scalars['Int'];
  yearAcquired: Scalars['Int'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationConfirmAssetsArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type MutationCreateUserInformationArgs = {
  email: Scalars['String'];
  fullname: Scalars['String'];
  password: Scalars['String'];
};


export type MutationDeleteBusinessAccountLinkArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
  taxYear: Scalars['Int'];
};


export type MutationDeleteLessorArgs = {
  contactLinkId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGeneratePaymentVoucherArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type MutationGeneratePresignedUploadFileArgs = {
  input: SasUploadFileInput;
};


export type MutationLinkBusinessAccountToUserArgs = {
  pin: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationReopenFilingArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type MutationRequestEmailLoginArgs = {
  email: Scalars['String'];
  pin: Scalars['String'];
};


export type MutationResendVerifyEmailArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  encryptedValue: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSaveAccountArgs = {
  account: AccountInput;
};


export type MutationSaveAssetsArgs = {
  accountId: Scalars['Int'];
  assets: Array<AssetInput>;
  jurisdiction: Scalars['Int'];
  taxYear: Scalars['Int'];
};


export type MutationSaveAssociatedAccountsArgs = {
  group: AssociatedAccountGroupInput;
};


export type MutationSaveLessorArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
  lessor: LessorInput;
  taxYear: Scalars['Int'];
};


export type MutationSkipAssociatedAccountsArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type MutationSkipLeasedAssetsArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type MutationSubmitFilingArgs = {
  filing: FilingSummaryInput;
};


export type MutationUpdateProfileArgs = {
  fullName: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};

export type NaicsCategory = {
  category: Scalars['String'];
  id: Scalars['ID'];
};

export type NaicsCode = {
  category: Scalars['String'];
  id: Scalars['ID'];
  subCategory: Scalars['String'];
};

export type NewLoginInfo = {
  exemptionLimit: Scalars['String'];
  taxYear: Scalars['Int'];
};

/** Other possible Point of Contact that the County can alternatively reach */
export type NonPrimaryContact = {
  cellphone?: Maybe<Scalars['String']>;
  contactType?: Maybe<ContactType>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: Maybe<Address>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  sameAsBusinessAddress: Scalars['Boolean'];
};

export type NonPrimaryContactInput = {
  cellphone?: InputMaybe<Scalars['String']>;
  contactType: ContactTypeInput;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: InputMaybe<AddressOtherContactInput>;
  name: Scalars['String'];
  phone: Scalars['String'];
  phoneExtension?: InputMaybe<Scalars['String']>;
  sameAsBusinessAddress: Scalars['Boolean'];
};

export type OathStatement = {
  countyAppealFormInfo?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  exemptionLimit?: Maybe<Scalars['Float']>;
  filingDueDate?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  interestStartDate?: Maybe<Scalars['String']>;
  penaltyAmount?: Maybe<Scalars['String']>;
  penaltyPercent?: Maybe<Scalars['String']>;
  taxYear?: Maybe<Scalars['Int']>;
};

export type PaymentInformation = {
  accountId: Scalars['Int'];
  amountEnclosed: Scalars['String'];
  assessorMailingAddress: BasicAddress;
  filingReportUrl: Scalars['String'];
  filingSubmissionDate: Scalars['String'];
  jurisdiction: Jurisdiction;
  mailingAddress: BasicAddress;
  paymentDueDate: Scalars['String'];
  paymentProviderName?: Maybe<Scalars['String']>;
  paymentProviderUrl?: Maybe<Scalars['String']>;
  situsAddress: BasicAddress;
  taxYear: Scalars['Int'];
  totalDue: Scalars['Float'];
};

export type PaymentVoucher = {
  fileUrl: Scalars['String'];
};

/** Main Point of Contact that will recieve mail from the County */
export type PrimaryContact = Contact & {
  cellphone?: Maybe<Scalars['String']>;
  contactType?: Maybe<ContactType>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress: Address;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  sameAsBusinessAddress: Scalars['Boolean'];
};

export type PrimaryContactInput = {
  cellphone?: InputMaybe<Scalars['String']>;
  contactType: ContactTypeInput;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress: AddressInput;
  name: Scalars['String'];
  phone: Scalars['String'];
  phoneExtension?: InputMaybe<Scalars['String']>;
  sameAsBusinessAddress: Scalars['Boolean'];
};

export type Query = {
  associatedAccounts: AssociatedAccountGroup;
  businessChangeFormUrl: Scalars['String'];
  contactTypes: Array<ContactType>;
  countries: Array<Country>;
  countySupport: Array<CountySupport>;
  entityTypes: Array<EntityType>;
  exemptionLimit: Scalars['Float'];
  getAccount?: Maybe<Account>;
  getAssets: Array<Asset>;
  getBusinessAccounts: Array<BusinessAccount>;
  getClassifications: Array<Classification>;
  getFilingSummary: FilingSummary;
  getJurisdictions: Array<Jurisdiction>;
  getLeasedAssets: Array<Lessor>;
  getNewLoginInfo: NewLoginInfo;
  getOathStatement: OathStatement;
  getPaymentInformation: PaymentInformation;
  getProfile: User;
  getSessionContext: SessionContext;
  getTaxRateByAccount: TaxRate;
  getThankYouInformation: ThankYouInformation;
  getTimeSpentFiling: Array<TimeSpentFiling>;
  naicsCategories: Array<NaicsCategory>;
  naicsCodes: Array<NaicsCode>;
};


export type QueryAssociatedAccountsArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type QueryBusinessChangeFormUrlArgs = {
  jurisdiction: Scalars['Int'];
};


export type QueryExemptionLimitArgs = {
  taxYear: Scalars['Int'];
};


export type QueryGetAccountArgs = {
  id: Scalars['ID'];
  jurisdiction: Scalars['Int'];
};


export type QueryGetAssetsArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type QueryGetFilingSummaryArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
  taxYear: Scalars['Int'];
};


export type QueryGetLeasedAssetsArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type QueryGetOathStatementArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type QueryGetPaymentInformationArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type QueryGetSessionContextArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};


export type QueryGetTaxRateByAccountArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
  taxYear: Scalars['Int'];
};


export type QueryGetThankYouInformationArgs = {
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
};

export type SasUploadFile = {
  blobName: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type SasUploadFileInput = {
  accountId: Scalars['Int'];
  fileName: Scalars['String'];
  jurisdiction: Scalars['Int'];
  path: Scalars['String'];
};

export type SessionContext = {
  accountNumber: Scalars['String'];
  businessName: Scalars['String'];
  completedSteps: Array<Scalars['String']>;
  delinquentTaxYears: Array<Scalars['String']>;
  taxYear: Scalars['Int'];
};

/**
 * According to Postal Addressing Standards - Appendix B
 * https://pe.usps.com/text/pub28/28apb.htm
 */
export enum State {
  /** Armed Forces Americas (except Canada) */
  Aa = 'AA',
  /** Armed Forces Europe, the Middle East, and Canada */
  Ae = 'AE',
  /** Alaska */
  Ak = 'AK',
  /** Alabama */
  Al = 'AL',
  /** Armed Forces Pacific */
  Ap = 'AP',
  /** Arkansas */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Arizona */
  Az = 'AZ',
  /** California */
  Ca = 'CA',
  /** Colorado */
  Co = 'CO',
  /** Connecticut */
  Ct = 'CT',
  /** District of Columbia */
  Dc = 'DC',
  /** Delaware */
  De = 'DE',
  /** Florida */
  Fl = 'FL',
  /** Federated Stated of Micronesia */
  Fm = 'FM',
  /** Georgia */
  Ga = 'GA',
  /** Guam */
  Gu = 'GU',
  /** Hawaii */
  Hi = 'HI',
  /** Iowa */
  Ia = 'IA',
  /** Idaho */
  Id = 'ID',
  /** Illinois */
  Il = 'IL',
  /** Indiana */
  In = 'IN',
  /** Kansas */
  Ks = 'KS',
  /** Kentucky */
  Ky = 'KY',
  /** Louisiana */
  La = 'LA',
  /** Massachusetts */
  Ma = 'MA',
  /** Maryland */
  Md = 'MD',
  /** Maine */
  Me = 'ME',
  /** Marshall Islands */
  Mh = 'MH',
  /** Michigan */
  Mi = 'MI',
  /** Minnesota */
  Mn = 'MN',
  /** Missouri */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Mississippi */
  Ms = 'MS',
  /** Montana */
  Mt = 'MT',
  /** North Carolina */
  Nc = 'NC',
  /** North Dakota */
  Nd = 'ND',
  /** Nebraska */
  Ne = 'NE',
  /** New Hampshire */
  Nh = 'NH',
  /** New Jersey */
  Nj = 'NJ',
  /** New Mexico */
  Nm = 'NM',
  /** Nevada */
  Nv = 'NV',
  /** New York */
  Ny = 'NY',
  /** Ohio */
  Oh = 'OH',
  /** Oklahoma */
  Ok = 'OK',
  /** Oregon */
  Or = 'OR',
  /** Pennsylvania */
  Pa = 'PA',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palau */
  Pw = 'PW',
  /** Rhode Island */
  Ri = 'RI',
  /** South Carolina */
  Sc = 'SC',
  /** South Dakota */
  Sd = 'SD',
  /** Tennessee */
  Tn = 'TN',
  /** Texas */
  Tx = 'TX',
  /** Utah */
  Ut = 'UT',
  /** Virginia */
  Va = 'VA',
  /** Virgin Islands */
  Vi = 'VI',
  /** Vermont */
  Vt = 'VT',
  /** Washington */
  Wa = 'WA',
  /** Wisconsin */
  Wi = 'WI',
  /** West Virginia */
  Wv = 'WV',
  /** Wyoming */
  Wy = 'WY'
}

export type SummaryAsset = {
  cost: Scalars['Float'];
  units: Scalars['Int'];
  value: Scalars['Float'];
};

export type SummaryContact = {
  contactType: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type SummaryContactInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type TaxRate = {
  id: Scalars['ID'];
  jurisdiction?: Maybe<Scalars['Int']>;
  taxDistrict?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxYear?: Maybe<Scalars['Int']>;
};

export type ThankYouInformation = {
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  filingSubmissionDate?: Maybe<Scalars['String']>;
  mailingAddress: BasicAddress;
  situsAddress: BasicAddress;
};

export type TimeSpentFiling = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type User = {
  fullName?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type GetAssetsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  taxYear: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetAssetsQuery = { exemptionLimit: number, getAssets: Array<{ id: string, classification?: string | null, classificationDescription?: string | null, description?: string | null, yearAcquired?: number | null, quantity?: number | null, costPer?: number | null, percentGood?: number | null, taxableValue?: number | null }>, getTaxRateByAccount: { id: string, taxDistrict?: string | null, taxRate?: number | null } };

export type SaveAssetsMutationVariables = Exact<{
  accountId: Scalars['Int'];
  taxYear: Scalars['Int'];
  jurisdiction: Scalars['Int'];
  assets: Array<AssetInput> | AssetInput;
}>;


export type SaveAssetsMutation = { saveAssets: Array<{ id: string, classification?: string | null, classificationDescription?: string | null, description?: string | null, yearAcquired?: number | null, quantity?: number | null, costPer?: number | null, percentGood?: number | null, taxableValue?: number | null }> };

export type ConfirmAssetsMutationVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type ConfirmAssetsMutation = { confirmAssets: boolean };

export type CalculatePercentGoodMutationVariables = Exact<{
  classification: Scalars['String'];
  yearAcquired: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type CalculatePercentGoodMutation = { calculatePercentGood: { percentGood: number } };

export type GetAssociatedAccountsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetAssociatedAccountsQuery = { associatedAccounts: { id: number, accounts: Array<{ id: number, accountId?: number | null, accountNumber: string, businessName?: string | null, address?: string | null }> } };

export type SaveAssociatedAccountsMutationVariables = Exact<{
  group: AssociatedAccountGroupInput;
}>;


export type SaveAssociatedAccountsMutation = { saveAssociatedAccounts: { id: number, accounts: Array<{ id: number, accountId?: number | null, accountNumber: string, businessName?: string | null, address?: string | null, isReconciled?: boolean | null }> } };

export type SkipAssociatedAccountsMutationVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type SkipAssociatedAccountsMutation = { skipAssociatedAccounts: boolean };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { login: { token: string } };

export type CreateUserInformationMutationVariables = Exact<{
  fullname: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateUserInformationMutation = { createUserInformation: boolean };

export type ResendVerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendVerifyEmailMutation = { resendVerifyEmail: boolean };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailMutation = { verifyEmail: { token: string } };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { changePassword: { token: string } };

export type RequestEmailLoginMutationVariables = Exact<{
  email: Scalars['String'];
  pin: Scalars['String'];
}>;


export type RequestEmailLoginMutation = { requestEmailLogin: boolean };

export type AuthByEmailCodeMutationVariables = Exact<{
  encryptedValue: Scalars['String'];
}>;


export type AuthByEmailCodeMutation = { authByEmailCode: { token: string } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  encryptedValue: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = { resetPassword: { token: string } };

export type GeneratePresignedUploadFileMutationVariables = Exact<{
  input: SasUploadFileInput;
}>;


export type GeneratePresignedUploadFileMutation = { generatePresignedUploadFile: { blobName: string, fileName: string, url: string } };

export type GetBusinessAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBusinessAccountsQuery = { getBusinessAccounts: Array<{ accountId: number, accountNumber: string, businessName: string, filingStatus?: string | null, taxArea: string, taxYear: number, taxableValue: number, taxDue: number, hasLinkedAccounts: boolean, linkedAccountsReconciled: boolean, isActive: boolean, isExempt: boolean, isReconciled: boolean, jurisdiction: { id: string, name: string }, address: { addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: { code: string, name: string } | null }, linkedAccounts: Array<{ id: number, accountId?: number | null, accountNumber: string, businessName?: string | null, address?: string | null, isReconciled?: boolean | null }> }> };

export type LinkBusinessAccountMutationVariables = Exact<{
  pin: Scalars['String'];
}>;


export type LinkBusinessAccountMutation = { linkBusinessAccountToUser: boolean };

export type DeleteBusinessAccountLinkMutationVariables = Exact<{
  accountId: Scalars['Int'];
  taxYear: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type DeleteBusinessAccountLinkMutation = { deleteBusinessAccountLink: boolean };

export type GetCountySupportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountySupportQuery = { countySupport: Array<{ id: string, countyName: string, address?: string | null, mailingAddress?: string | null, email?: string | null, phoneNumber?: string | null, workDays?: string | null, workHours?: string | null, supportUrl?: string | null, contacts: Array<{ id: string, name: string, title?: string | null, email?: string | null, phoneNumber?: string | null }> }>, getJurisdictions: Array<{ id: string, name: string }> };

export type GetJurisdictionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJurisdictionsQuery = { getJurisdictions: Array<{ id: string, name: string }> };

export type GetLeasedAssetsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetLeasedAssetsQuery = { getLeasedAssets: Array<{ id: string, contactLinkId?: string | null, fullName?: string | null, address1?: string | null, address2?: string | null, state?: State | null, city?: string | null, zipcode?: string | null, assets: Array<{ assetId: string, leaseId?: string | null, leaseNumber?: string | null, leaseAccountId?: string | null, contactLinkId?: string | null, description?: string | null, yearAcquired?: number | null, quantity?: number | null, costPer?: number | null, leaseStartDate?: string | null, leaseEndDate?: string | null, annualLeaseAmount?: number | null, initialValue?: number | null, fileUrl?: string | null, fileName?: string | null, blobName?: string | null }> }> };

export type SaveLessorMutationVariables = Exact<{
  accountId: Scalars['Int'];
  taxYear: Scalars['Int'];
  jurisdiction: Scalars['Int'];
  lessor: LessorInput;
}>;


export type SaveLessorMutation = { saveLessor: { id: string, contactLinkId?: string | null, fullName?: string | null, address1?: string | null, address2?: string | null, state?: State | null, city?: string | null, zipcode?: string | null, assets: Array<{ assetId: string, leaseId?: string | null, leaseNumber?: string | null, leaseAccountId?: string | null, contactLinkId?: string | null, description?: string | null, yearAcquired?: number | null, quantity?: number | null, costPer?: number | null, leaseStartDate?: string | null, leaseEndDate?: string | null, annualLeaseAmount?: number | null, initialValue?: number | null, fileUrl?: string | null, fileName?: string | null, blobName?: string | null }> } };

export type DeleteLessorMutationVariables = Exact<{
  contactLinkId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type DeleteLessorMutation = { deleteLessor?: { contactLinkId: string } | null };

export type SkipLeasedAssetsMutationVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type SkipLeasedAssetsMutation = { skipLeasedAssets: boolean };

export type GetNewLoginInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNewLoginInfoQuery = { getNewLoginInfo: { taxYear: number, exemptionLimit: string } };

export type GetAccountInformationQueryVariables = Exact<{
  accountId: Scalars['ID'];
  jurisdiction: Scalars['Int'];
}>;


export type GetAccountInformationQuery = { businessChangeFormUrl: string, getAccount?: { id: string, yearOpened?: number | null, businessDescription?: string | null, registeredEntityName?: string | null, naicsCode?: string | null, isHomeBusiness?: boolean | null, employerIdentificationNumber?: string | null, websiteUrl?: string | null, businessAddress: { addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: { code: string, name: string } | null }, registeredEntityType?: { id: string, label: string } | null, primaryContact?: { id: string, name?: string | null, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, sameAsBusinessAddress: boolean, contactType?: { id: string, label: string } | null, mailingAddress: { inCareOf?: string | null, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: { code: string, name: string } | null } } | null, otherContacts: Array<{ id: string, name?: string | null, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, sameAsBusinessAddress: boolean, contactType?: { id: string, label: string } | null, mailingAddress?: { addressLine1?: string | null, addressLine2?: string | null, city?: string | null, inCareOf?: string | null, state?: string | null, zipCode?: string | null, country?: { code: string, name: string } | null } | null }> } | null, contactTypes: Array<{ id: string, label: string }>, entityTypes: Array<{ id: string, label: string }>, countries: Array<{ code: string, name: string }>, naicsCategories: Array<{ id: string, category: string }>, naicsCodes: Array<{ id: string, category: string, subCategory: string }> };

export type SaveAccountMutationVariables = Exact<{
  account: AccountInput;
}>;


export type SaveAccountMutation = { saveAccount?: { id: string, yearOpened?: number | null, businessDescription?: string | null, registeredEntityName?: string | null, naicsCode?: string | null, isHomeBusiness?: boolean | null, employerIdentificationNumber?: string | null, websiteUrl?: string | null, registeredEntityType?: { id: string, label: string } | null, primaryContact?: { id: string, name?: string | null, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, sameAsBusinessAddress: boolean, contactType?: { id: string, label: string } | null, mailingAddress: { inCareOf?: string | null, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: { code: string, name: string } | null } } | null, otherContacts: Array<{ id: string, name?: string | null, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, sameAsBusinessAddress: boolean, contactType?: { id: string, label: string } | null, mailingAddress?: { addressLine1?: string | null, addressLine2?: string | null, inCareOf?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, country?: { code: string, name: string } | null } | null }> } | null };

export type GetPaymentInformationQueryVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetPaymentInformationQuery = { getPaymentInformation: { accountId: number, taxYear: number, filingSubmissionDate: string, paymentDueDate: string, totalDue: number, amountEnclosed: string, filingReportUrl: string, paymentProviderUrl?: string | null, paymentProviderName?: string | null, jurisdiction: { id: string, name: string }, situsAddress: { name?: string | null, address?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, phone?: string | null }, mailingAddress: { name?: string | null, address?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, phone?: string | null }, assessorMailingAddress: { name?: string | null, title?: string | null, address?: string | null, city?: string | null, state?: string | null, zipCode?: string | null, phone?: string | null } } };

export type GeneratePaymentVoucherMutationVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GeneratePaymentVoucherMutation = { generatePaymentVoucher: { fileUrl: string } };

export type GetSessionContextQueryVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetSessionContextQuery = { getSessionContext: { accountNumber: string, businessName: string, completedSteps: Array<string>, taxYear: number, delinquentTaxYears: Array<string> } };

export type GetFilingSummaryQueryVariables = Exact<{
  accountId: Scalars['Int'];
  taxYear: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetFilingSummaryQuery = { getFilingSummary: { accountId: number, jurisdiction: number, taxYear: number, netTotalCost: number, netTotalValue: number, totalTaxableValue: number, taxableDue: number, amountDue: number, otherChargesCredits: number, penalty: number, interest: number, delinquentTax: number, payments: number, leaseReportFileUrl?: string | null, nonLeaseReportFileUrl?: string | null, previousYearAssets: { units: number, cost: number, value: number }, addedAssets: { units: number, cost: number, value: number }, removedAssets: { units: number, cost: number, value: number }, changedAssets: { units: number, cost: number, value: number }, taxRate: { id: string, taxDistrict?: string | null, taxRate?: number | null }, contacts: Array<{ id: string, name: string, email: string, phone: string, contactType: string }> }, getOathStatement: { countyName?: string | null, taxYear?: number | null, filingDueDate?: string | null, exemptionLimit?: number | null, interestRate?: number | null, interestStartDate?: string | null, penaltyAmount?: string | null, penaltyPercent?: string | null, countyAppealFormInfo?: string | null }, getTimeSpentFiling: Array<{ id: string, description: string }> };

export type SubmitFilingMutationVariables = Exact<{
  filing: FilingSummaryInput;
}>;


export type SubmitFilingMutation = { submitFiling?: boolean | null };

export type ReopenFilingMutationVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type ReopenFilingMutation = { reopenFiling?: boolean | null };

export type GetThankYouInformationQueryVariables = Exact<{
  accountId: Scalars['Int'];
  jurisdiction: Scalars['Int'];
}>;


export type GetThankYouInformationQuery = { getThankYouInformation: { fileUrl?: string | null, filingSubmissionDate?: string | null, mailingAddress: { address?: string | null, city?: string | null, name?: string | null, phone?: string | null, state?: string | null, zipCode?: string | null }, situsAddress: { address?: string | null, city?: string | null, name?: string | null, phone?: string | null, state?: string | null, zipCode?: string | null } } };

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { getProfile: { userName: string, fullName?: string | null } };

export type UpdateProfileMutationVariables = Exact<{
  fullName: Scalars['String'];
}>;


export type UpdateProfileMutation = { updateProfile: boolean };


export const GetAssetsDocument = `
    query GetAssets($accountId: Int!, $taxYear: Int!, $jurisdiction: Int!) {
  getAssets(accountId: $accountId, jurisdiction: $jurisdiction) {
    id
    classification
    classificationDescription
    description
    yearAcquired
    quantity
    costPer
    percentGood
    taxableValue
  }
  getTaxRateByAccount(
    accountId: $accountId
    taxYear: $taxYear
    jurisdiction: $jurisdiction
  ) {
    id
    taxDistrict
    taxRate
  }
  exemptionLimit(taxYear: $taxYear)
}
    `;
export const useGetAssetsQuery = <
      TData = GetAssetsQuery,
      TError = unknown
    >(
      variables: GetAssetsQueryVariables,
      options?: UseQueryOptions<GetAssetsQuery, TError, TData>
    ) =>
    useQuery<GetAssetsQuery, TError, TData>(
      ['GetAssets', variables],
      useFetchData<GetAssetsQuery, GetAssetsQueryVariables>(GetAssetsDocument).bind(null, variables),
      options
    );
export const SaveAssetsDocument = `
    mutation SaveAssets($accountId: Int!, $taxYear: Int!, $jurisdiction: Int!, $assets: [AssetInput!]!) {
  saveAssets(
    accountId: $accountId
    taxYear: $taxYear
    jurisdiction: $jurisdiction
    assets: $assets
  ) {
    id
    classification
    classificationDescription
    description
    yearAcquired
    quantity
    costPer
    percentGood
    taxableValue
  }
}
    `;
export const useSaveAssetsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveAssetsMutation, TError, SaveAssetsMutationVariables, TContext>) =>
    useMutation<SaveAssetsMutation, TError, SaveAssetsMutationVariables, TContext>(
      ['SaveAssets'],
      useFetchData<SaveAssetsMutation, SaveAssetsMutationVariables>(SaveAssetsDocument),
      options
    );
export const ConfirmAssetsDocument = `
    mutation ConfirmAssets($accountId: Int!, $jurisdiction: Int!) {
  confirmAssets(accountId: $accountId, jurisdiction: $jurisdiction)
}
    `;
export const useConfirmAssetsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ConfirmAssetsMutation, TError, ConfirmAssetsMutationVariables, TContext>) =>
    useMutation<ConfirmAssetsMutation, TError, ConfirmAssetsMutationVariables, TContext>(
      ['ConfirmAssets'],
      useFetchData<ConfirmAssetsMutation, ConfirmAssetsMutationVariables>(ConfirmAssetsDocument),
      options
    );
export const CalculatePercentGoodDocument = `
    mutation CalculatePercentGood($classification: String!, $yearAcquired: Int!, $jurisdiction: Int!) {
  calculatePercentGood(
    classification: $classification
    yearAcquired: $yearAcquired
    jurisdiction: $jurisdiction
  ) {
    percentGood
  }
}
    `;
export const useCalculatePercentGoodMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CalculatePercentGoodMutation, TError, CalculatePercentGoodMutationVariables, TContext>) =>
    useMutation<CalculatePercentGoodMutation, TError, CalculatePercentGoodMutationVariables, TContext>(
      ['CalculatePercentGood'],
      useFetchData<CalculatePercentGoodMutation, CalculatePercentGoodMutationVariables>(CalculatePercentGoodDocument),
      options
    );
export const GetAssociatedAccountsDocument = `
    query GetAssociatedAccounts($accountId: Int!, $jurisdiction: Int!) {
  associatedAccounts(accountId: $accountId, jurisdiction: $jurisdiction) {
    id
    accounts {
      id
      accountId
      accountNumber
      businessName
      address
    }
  }
}
    `;
export const useGetAssociatedAccountsQuery = <
      TData = GetAssociatedAccountsQuery,
      TError = unknown
    >(
      variables: GetAssociatedAccountsQueryVariables,
      options?: UseQueryOptions<GetAssociatedAccountsQuery, TError, TData>
    ) =>
    useQuery<GetAssociatedAccountsQuery, TError, TData>(
      ['GetAssociatedAccounts', variables],
      useFetchData<GetAssociatedAccountsQuery, GetAssociatedAccountsQueryVariables>(GetAssociatedAccountsDocument).bind(null, variables),
      options
    );
export const SaveAssociatedAccountsDocument = `
    mutation SaveAssociatedAccounts($group: AssociatedAccountGroupInput!) {
  saveAssociatedAccounts(group: $group) {
    id
    accounts {
      id
      accountId
      accountNumber
      businessName
      address
      isReconciled
    }
  }
}
    `;
export const useSaveAssociatedAccountsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveAssociatedAccountsMutation, TError, SaveAssociatedAccountsMutationVariables, TContext>) =>
    useMutation<SaveAssociatedAccountsMutation, TError, SaveAssociatedAccountsMutationVariables, TContext>(
      ['SaveAssociatedAccounts'],
      useFetchData<SaveAssociatedAccountsMutation, SaveAssociatedAccountsMutationVariables>(SaveAssociatedAccountsDocument),
      options
    );
export const SkipAssociatedAccountsDocument = `
    mutation SkipAssociatedAccounts($accountId: Int!, $jurisdiction: Int!) {
  skipAssociatedAccounts(accountId: $accountId, jurisdiction: $jurisdiction)
}
    `;
export const useSkipAssociatedAccountsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SkipAssociatedAccountsMutation, TError, SkipAssociatedAccountsMutationVariables, TContext>) =>
    useMutation<SkipAssociatedAccountsMutation, TError, SkipAssociatedAccountsMutationVariables, TContext>(
      ['SkipAssociatedAccounts'],
      useFetchData<SkipAssociatedAccountsMutation, SkipAssociatedAccountsMutationVariables>(SkipAssociatedAccountsDocument),
      options
    );
export const LoginDocument = `
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    token
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['Login'],
      useFetchData<LoginMutation, LoginMutationVariables>(LoginDocument),
      options
    );
export const CreateUserInformationDocument = `
    mutation CreateUserInformation($fullname: String!, $email: String!, $password: String!) {
  createUserInformation(fullname: $fullname, email: $email, password: $password)
}
    `;
export const useCreateUserInformationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserInformationMutation, TError, CreateUserInformationMutationVariables, TContext>) =>
    useMutation<CreateUserInformationMutation, TError, CreateUserInformationMutationVariables, TContext>(
      ['CreateUserInformation'],
      useFetchData<CreateUserInformationMutation, CreateUserInformationMutationVariables>(CreateUserInformationDocument),
      options
    );
export const ResendVerifyEmailDocument = `
    mutation ResendVerifyEmail($email: String!) {
  resendVerifyEmail(email: $email)
}
    `;
export const useResendVerifyEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResendVerifyEmailMutation, TError, ResendVerifyEmailMutationVariables, TContext>) =>
    useMutation<ResendVerifyEmailMutation, TError, ResendVerifyEmailMutationVariables, TContext>(
      ['ResendVerifyEmail'],
      useFetchData<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>(ResendVerifyEmailDocument),
      options
    );
export const VerifyEmailDocument = `
    mutation VerifyEmail($token: String!) {
  verifyEmail(token: $token) {
    token
  }
}
    `;
export const useVerifyEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>) =>
    useMutation<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>(
      ['VerifyEmail'],
      useFetchData<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument),
      options
    );
export const ChangePasswordDocument = `
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    token
  }
}
    `;
export const useChangePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>) =>
    useMutation<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>(
      ['ChangePassword'],
      useFetchData<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument),
      options
    );
export const RequestEmailLoginDocument = `
    mutation RequestEmailLogin($email: String!, $pin: String!) {
  requestEmailLogin(email: $email, pin: $pin)
}
    `;
export const useRequestEmailLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestEmailLoginMutation, TError, RequestEmailLoginMutationVariables, TContext>) =>
    useMutation<RequestEmailLoginMutation, TError, RequestEmailLoginMutationVariables, TContext>(
      ['RequestEmailLogin'],
      useFetchData<RequestEmailLoginMutation, RequestEmailLoginMutationVariables>(RequestEmailLoginDocument),
      options
    );
export const AuthByEmailCodeDocument = `
    mutation AuthByEmailCode($encryptedValue: String!) {
  authByEmailCode(encryptedValue: $encryptedValue) {
    token
  }
}
    `;
export const useAuthByEmailCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AuthByEmailCodeMutation, TError, AuthByEmailCodeMutationVariables, TContext>) =>
    useMutation<AuthByEmailCodeMutation, TError, AuthByEmailCodeMutationVariables, TContext>(
      ['AuthByEmailCode'],
      useFetchData<AuthByEmailCodeMutation, AuthByEmailCodeMutationVariables>(AuthByEmailCodeDocument),
      options
    );
export const ForgotPasswordDocument = `
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export const useForgotPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ForgotPasswordMutation, TError, ForgotPasswordMutationVariables, TContext>) =>
    useMutation<ForgotPasswordMutation, TError, ForgotPasswordMutationVariables, TContext>(
      ['ForgotPassword'],
      useFetchData<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument),
      options
    );
export const ResetPasswordDocument = `
    mutation ResetPassword($encryptedValue: String!, $password: String!) {
  resetPassword(encryptedValue: $encryptedValue, password: $password) {
    token
  }
}
    `;
export const useResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>) =>
    useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
      ['ResetPassword'],
      useFetchData<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument),
      options
    );
export const GeneratePresignedUploadFileDocument = `
    mutation GeneratePresignedUploadFile($input: SASUploadFileInput!) {
  generatePresignedUploadFile(input: $input) {
    blobName
    fileName
    url
  }
}
    `;
export const useGeneratePresignedUploadFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GeneratePresignedUploadFileMutation, TError, GeneratePresignedUploadFileMutationVariables, TContext>) =>
    useMutation<GeneratePresignedUploadFileMutation, TError, GeneratePresignedUploadFileMutationVariables, TContext>(
      ['GeneratePresignedUploadFile'],
      useFetchData<GeneratePresignedUploadFileMutation, GeneratePresignedUploadFileMutationVariables>(GeneratePresignedUploadFileDocument),
      options
    );
export const GetBusinessAccountsDocument = `
    query GetBusinessAccounts {
  getBusinessAccounts {
    accountId
    accountNumber
    businessName
    filingStatus
    taxArea
    taxYear
    taxableValue
    taxDue
    hasLinkedAccounts
    linkedAccountsReconciled
    isActive
    isExempt
    isReconciled
    jurisdiction {
      id
      name
    }
    address {
      addressLine1
      addressLine2
      city
      state
      zipCode
      country {
        code
        name
      }
    }
    linkedAccounts {
      id
      accountId
      accountNumber
      businessName
      address
      isReconciled
    }
  }
}
    `;
export const useGetBusinessAccountsQuery = <
      TData = GetBusinessAccountsQuery,
      TError = unknown
    >(
      variables?: GetBusinessAccountsQueryVariables,
      options?: UseQueryOptions<GetBusinessAccountsQuery, TError, TData>
    ) =>
    useQuery<GetBusinessAccountsQuery, TError, TData>(
      variables === undefined ? ['GetBusinessAccounts'] : ['GetBusinessAccounts', variables],
      useFetchData<GetBusinessAccountsQuery, GetBusinessAccountsQueryVariables>(GetBusinessAccountsDocument).bind(null, variables),
      options
    );
export const LinkBusinessAccountDocument = `
    mutation LinkBusinessAccount($pin: String!) {
  linkBusinessAccountToUser(pin: $pin)
}
    `;
export const useLinkBusinessAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LinkBusinessAccountMutation, TError, LinkBusinessAccountMutationVariables, TContext>) =>
    useMutation<LinkBusinessAccountMutation, TError, LinkBusinessAccountMutationVariables, TContext>(
      ['LinkBusinessAccount'],
      useFetchData<LinkBusinessAccountMutation, LinkBusinessAccountMutationVariables>(LinkBusinessAccountDocument),
      options
    );
export const DeleteBusinessAccountLinkDocument = `
    mutation DeleteBusinessAccountLink($accountId: Int!, $taxYear: Int!, $jurisdiction: Int!) {
  deleteBusinessAccountLink(
    accountId: $accountId
    taxYear: $taxYear
    jurisdiction: $jurisdiction
  )
}
    `;
export const useDeleteBusinessAccountLinkMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteBusinessAccountLinkMutation, TError, DeleteBusinessAccountLinkMutationVariables, TContext>) =>
    useMutation<DeleteBusinessAccountLinkMutation, TError, DeleteBusinessAccountLinkMutationVariables, TContext>(
      ['DeleteBusinessAccountLink'],
      useFetchData<DeleteBusinessAccountLinkMutation, DeleteBusinessAccountLinkMutationVariables>(DeleteBusinessAccountLinkDocument),
      options
    );
export const GetCountySupportDocument = `
    query GetCountySupport {
  countySupport {
    id
    countyName
    address
    mailingAddress
    email
    phoneNumber
    workDays
    workHours
    supportUrl
    contacts {
      id
      name
      title
      email
      phoneNumber
    }
  }
  getJurisdictions {
    id
    name
  }
}
    `;
export const useGetCountySupportQuery = <
      TData = GetCountySupportQuery,
      TError = unknown
    >(
      variables?: GetCountySupportQueryVariables,
      options?: UseQueryOptions<GetCountySupportQuery, TError, TData>
    ) =>
    useQuery<GetCountySupportQuery, TError, TData>(
      variables === undefined ? ['GetCountySupport'] : ['GetCountySupport', variables],
      useFetchData<GetCountySupportQuery, GetCountySupportQueryVariables>(GetCountySupportDocument).bind(null, variables),
      options
    );
export const GetJurisdictionsDocument = `
    query GetJurisdictions {
  getJurisdictions {
    id
    name
  }
}
    `;
export const useGetJurisdictionsQuery = <
      TData = GetJurisdictionsQuery,
      TError = unknown
    >(
      variables?: GetJurisdictionsQueryVariables,
      options?: UseQueryOptions<GetJurisdictionsQuery, TError, TData>
    ) =>
    useQuery<GetJurisdictionsQuery, TError, TData>(
      variables === undefined ? ['GetJurisdictions'] : ['GetJurisdictions', variables],
      useFetchData<GetJurisdictionsQuery, GetJurisdictionsQueryVariables>(GetJurisdictionsDocument).bind(null, variables),
      options
    );
export const GetLeasedAssetsDocument = `
    query GetLeasedAssets($accountId: Int!, $jurisdiction: Int!) {
  getLeasedAssets(accountId: $accountId, jurisdiction: $jurisdiction) {
    id
    contactLinkId
    fullName
    address1
    address2
    state
    city
    zipcode
    assets {
      assetId
      leaseId
      leaseNumber
      leaseAccountId
      contactLinkId
      description
      yearAcquired
      quantity
      costPer
      leaseStartDate
      leaseEndDate
      annualLeaseAmount
      initialValue
      fileUrl
      fileName
      blobName
    }
  }
}
    `;
export const useGetLeasedAssetsQuery = <
      TData = GetLeasedAssetsQuery,
      TError = unknown
    >(
      variables: GetLeasedAssetsQueryVariables,
      options?: UseQueryOptions<GetLeasedAssetsQuery, TError, TData>
    ) =>
    useQuery<GetLeasedAssetsQuery, TError, TData>(
      ['GetLeasedAssets', variables],
      useFetchData<GetLeasedAssetsQuery, GetLeasedAssetsQueryVariables>(GetLeasedAssetsDocument).bind(null, variables),
      options
    );
export const SaveLessorDocument = `
    mutation SaveLessor($accountId: Int!, $taxYear: Int!, $jurisdiction: Int!, $lessor: LessorInput!) {
  saveLessor(
    accountId: $accountId
    taxYear: $taxYear
    jurisdiction: $jurisdiction
    lessor: $lessor
  ) {
    id
    contactLinkId
    fullName
    address1
    address2
    state
    city
    zipcode
    assets {
      assetId
      leaseId
      leaseNumber
      leaseAccountId
      contactLinkId
      description
      yearAcquired
      quantity
      costPer
      leaseStartDate
      leaseEndDate
      annualLeaseAmount
      initialValue
      fileUrl
      fileName
      blobName
    }
  }
}
    `;
export const useSaveLessorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveLessorMutation, TError, SaveLessorMutationVariables, TContext>) =>
    useMutation<SaveLessorMutation, TError, SaveLessorMutationVariables, TContext>(
      ['SaveLessor'],
      useFetchData<SaveLessorMutation, SaveLessorMutationVariables>(SaveLessorDocument),
      options
    );
export const DeleteLessorDocument = `
    mutation DeleteLessor($contactLinkId: Int!, $jurisdiction: Int!) {
  deleteLessor(contactLinkId: $contactLinkId, jurisdiction: $jurisdiction) {
    contactLinkId
  }
}
    `;
export const useDeleteLessorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteLessorMutation, TError, DeleteLessorMutationVariables, TContext>) =>
    useMutation<DeleteLessorMutation, TError, DeleteLessorMutationVariables, TContext>(
      ['DeleteLessor'],
      useFetchData<DeleteLessorMutation, DeleteLessorMutationVariables>(DeleteLessorDocument),
      options
    );
export const SkipLeasedAssetsDocument = `
    mutation SkipLeasedAssets($accountId: Int!, $jurisdiction: Int!) {
  skipLeasedAssets(accountId: $accountId, jurisdiction: $jurisdiction)
}
    `;
export const useSkipLeasedAssetsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SkipLeasedAssetsMutation, TError, SkipLeasedAssetsMutationVariables, TContext>) =>
    useMutation<SkipLeasedAssetsMutation, TError, SkipLeasedAssetsMutationVariables, TContext>(
      ['SkipLeasedAssets'],
      useFetchData<SkipLeasedAssetsMutation, SkipLeasedAssetsMutationVariables>(SkipLeasedAssetsDocument),
      options
    );
export const GetNewLoginInfoDocument = `
    query GetNewLoginInfo {
  getNewLoginInfo {
    taxYear
    exemptionLimit
  }
}
    `;
export const useGetNewLoginInfoQuery = <
      TData = GetNewLoginInfoQuery,
      TError = unknown
    >(
      variables?: GetNewLoginInfoQueryVariables,
      options?: UseQueryOptions<GetNewLoginInfoQuery, TError, TData>
    ) =>
    useQuery<GetNewLoginInfoQuery, TError, TData>(
      variables === undefined ? ['GetNewLoginInfo'] : ['GetNewLoginInfo', variables],
      useFetchData<GetNewLoginInfoQuery, GetNewLoginInfoQueryVariables>(GetNewLoginInfoDocument).bind(null, variables),
      options
    );
export const GetAccountInformationDocument = `
    query GetAccountInformation($accountId: ID!, $jurisdiction: Int!) {
  getAccount(id: $accountId, jurisdiction: $jurisdiction) {
    id
    businessAddress {
      addressLine1
      addressLine2
      city
      state
      zipCode
      country {
        code
        name
      }
    }
    yearOpened
    businessDescription
    registeredEntityName
    registeredEntityType {
      id
      label
    }
    naicsCode
    isHomeBusiness
    employerIdentificationNumber
    websiteUrl
    primaryContact {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      sameAsBusinessAddress
      mailingAddress {
        inCareOf
        addressLine1
        addressLine2
        city
        state
        zipCode
        country {
          code
          name
        }
      }
    }
    otherContacts {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      sameAsBusinessAddress
      mailingAddress {
        addressLine1
        addressLine2
        city
        inCareOf
        state
        zipCode
        country {
          code
          name
        }
      }
    }
  }
  contactTypes {
    id
    label
  }
  entityTypes {
    id
    label
  }
  countries {
    code
    name
  }
  naicsCategories {
    id
    category
  }
  naicsCodes {
    id
    category
    subCategory
  }
  businessChangeFormUrl(jurisdiction: $jurisdiction)
}
    `;
export const useGetAccountInformationQuery = <
      TData = GetAccountInformationQuery,
      TError = unknown
    >(
      variables: GetAccountInformationQueryVariables,
      options?: UseQueryOptions<GetAccountInformationQuery, TError, TData>
    ) =>
    useQuery<GetAccountInformationQuery, TError, TData>(
      ['GetAccountInformation', variables],
      useFetchData<GetAccountInformationQuery, GetAccountInformationQueryVariables>(GetAccountInformationDocument).bind(null, variables),
      options
    );
export const SaveAccountDocument = `
    mutation SaveAccount($account: AccountInput!) {
  saveAccount(account: $account) {
    id
    yearOpened
    businessDescription
    registeredEntityName
    registeredEntityType {
      id
      label
    }
    naicsCode
    isHomeBusiness
    employerIdentificationNumber
    websiteUrl
    primaryContact {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      sameAsBusinessAddress
      mailingAddress {
        inCareOf
        addressLine1
        addressLine2
        city
        state
        zipCode
        country {
          code
          name
        }
      }
    }
    otherContacts {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      sameAsBusinessAddress
      mailingAddress {
        addressLine1
        addressLine2
        inCareOf
        city
        state
        zipCode
        country {
          code
          name
        }
      }
    }
  }
}
    `;
export const useSaveAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveAccountMutation, TError, SaveAccountMutationVariables, TContext>) =>
    useMutation<SaveAccountMutation, TError, SaveAccountMutationVariables, TContext>(
      ['SaveAccount'],
      useFetchData<SaveAccountMutation, SaveAccountMutationVariables>(SaveAccountDocument),
      options
    );
export const GetPaymentInformationDocument = `
    query GetPaymentInformation($accountId: Int!, $jurisdiction: Int!) {
  getPaymentInformation(accountId: $accountId, jurisdiction: $jurisdiction) {
    accountId
    taxYear
    jurisdiction {
      id
      name
    }
    situsAddress {
      name
      address
      city
      state
      zipCode
      phone
    }
    mailingAddress {
      name
      address
      city
      state
      zipCode
      phone
    }
    assessorMailingAddress {
      name
      title
      address
      city
      state
      zipCode
      phone
    }
    filingSubmissionDate
    paymentDueDate
    totalDue
    amountEnclosed
    filingReportUrl
    paymentProviderUrl
    paymentProviderName
  }
}
    `;
export const useGetPaymentInformationQuery = <
      TData = GetPaymentInformationQuery,
      TError = unknown
    >(
      variables: GetPaymentInformationQueryVariables,
      options?: UseQueryOptions<GetPaymentInformationQuery, TError, TData>
    ) =>
    useQuery<GetPaymentInformationQuery, TError, TData>(
      ['GetPaymentInformation', variables],
      useFetchData<GetPaymentInformationQuery, GetPaymentInformationQueryVariables>(GetPaymentInformationDocument).bind(null, variables),
      options
    );
export const GeneratePaymentVoucherDocument = `
    mutation GeneratePaymentVoucher($accountId: Int!, $jurisdiction: Int!) {
  generatePaymentVoucher(accountId: $accountId, jurisdiction: $jurisdiction) {
    fileUrl
  }
}
    `;
export const useGeneratePaymentVoucherMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GeneratePaymentVoucherMutation, TError, GeneratePaymentVoucherMutationVariables, TContext>) =>
    useMutation<GeneratePaymentVoucherMutation, TError, GeneratePaymentVoucherMutationVariables, TContext>(
      ['GeneratePaymentVoucher'],
      useFetchData<GeneratePaymentVoucherMutation, GeneratePaymentVoucherMutationVariables>(GeneratePaymentVoucherDocument),
      options
    );
export const GetSessionContextDocument = `
    query GetSessionContext($accountId: Int!, $jurisdiction: Int!) {
  getSessionContext(accountId: $accountId, jurisdiction: $jurisdiction) {
    accountNumber
    businessName
    completedSteps
    taxYear
    delinquentTaxYears
  }
}
    `;
export const useGetSessionContextQuery = <
      TData = GetSessionContextQuery,
      TError = unknown
    >(
      variables: GetSessionContextQueryVariables,
      options?: UseQueryOptions<GetSessionContextQuery, TError, TData>
    ) =>
    useQuery<GetSessionContextQuery, TError, TData>(
      ['GetSessionContext', variables],
      useFetchData<GetSessionContextQuery, GetSessionContextQueryVariables>(GetSessionContextDocument).bind(null, variables),
      options
    );
export const GetFilingSummaryDocument = `
    query GetFilingSummary($accountId: Int!, $taxYear: Int!, $jurisdiction: Int!) {
  getFilingSummary(
    accountId: $accountId
    taxYear: $taxYear
    jurisdiction: $jurisdiction
  ) {
    accountId
    jurisdiction
    taxYear
    previousYearAssets {
      units
      cost
      value
    }
    addedAssets {
      units
      cost
      value
    }
    removedAssets {
      units
      cost
      value
    }
    changedAssets {
      units
      cost
      value
    }
    netTotalCost
    netTotalValue
    totalTaxableValue
    taxableDue
    amountDue
    otherChargesCredits
    penalty
    interest
    delinquentTax
    payments
    taxRate {
      id
      taxDistrict
      taxRate
    }
    leaseReportFileUrl
    nonLeaseReportFileUrl
    contacts {
      id
      name
      email
      phone
      contactType
    }
  }
  getOathStatement(accountId: $accountId, jurisdiction: $jurisdiction) {
    countyName
    taxYear
    filingDueDate
    exemptionLimit
    interestRate
    interestStartDate
    penaltyAmount
    penaltyPercent
    countyAppealFormInfo
  }
  getTimeSpentFiling {
    id
    description
  }
}
    `;
export const useGetFilingSummaryQuery = <
      TData = GetFilingSummaryQuery,
      TError = unknown
    >(
      variables: GetFilingSummaryQueryVariables,
      options?: UseQueryOptions<GetFilingSummaryQuery, TError, TData>
    ) =>
    useQuery<GetFilingSummaryQuery, TError, TData>(
      ['GetFilingSummary', variables],
      useFetchData<GetFilingSummaryQuery, GetFilingSummaryQueryVariables>(GetFilingSummaryDocument).bind(null, variables),
      options
    );
export const SubmitFilingDocument = `
    mutation SubmitFiling($filing: FilingSummaryInput!) {
  submitFiling(filing: $filing)
}
    `;
export const useSubmitFilingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SubmitFilingMutation, TError, SubmitFilingMutationVariables, TContext>) =>
    useMutation<SubmitFilingMutation, TError, SubmitFilingMutationVariables, TContext>(
      ['SubmitFiling'],
      useFetchData<SubmitFilingMutation, SubmitFilingMutationVariables>(SubmitFilingDocument),
      options
    );
export const ReopenFilingDocument = `
    mutation ReopenFiling($accountId: Int!, $jurisdiction: Int!) {
  reopenFiling(accountId: $accountId, jurisdiction: $jurisdiction)
}
    `;
export const useReopenFilingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ReopenFilingMutation, TError, ReopenFilingMutationVariables, TContext>) =>
    useMutation<ReopenFilingMutation, TError, ReopenFilingMutationVariables, TContext>(
      ['ReopenFiling'],
      useFetchData<ReopenFilingMutation, ReopenFilingMutationVariables>(ReopenFilingDocument),
      options
    );
export const GetThankYouInformationDocument = `
    query GetThankYouInformation($accountId: Int!, $jurisdiction: Int!) {
  getThankYouInformation(accountId: $accountId, jurisdiction: $jurisdiction) {
    fileUrl
    filingSubmissionDate
    mailingAddress {
      address
      city
      name
      phone
      state
      zipCode
    }
    situsAddress {
      address
      city
      name
      phone
      state
      zipCode
    }
  }
}
    `;
export const useGetThankYouInformationQuery = <
      TData = GetThankYouInformationQuery,
      TError = unknown
    >(
      variables: GetThankYouInformationQueryVariables,
      options?: UseQueryOptions<GetThankYouInformationQuery, TError, TData>
    ) =>
    useQuery<GetThankYouInformationQuery, TError, TData>(
      ['GetThankYouInformation', variables],
      useFetchData<GetThankYouInformationQuery, GetThankYouInformationQueryVariables>(GetThankYouInformationDocument).bind(null, variables),
      options
    );
export const GetProfileDocument = `
    query GetProfile {
  getProfile {
    userName
    fullName
  }
}
    `;
export const useGetProfileQuery = <
      TData = GetProfileQuery,
      TError = unknown
    >(
      variables?: GetProfileQueryVariables,
      options?: UseQueryOptions<GetProfileQuery, TError, TData>
    ) =>
    useQuery<GetProfileQuery, TError, TData>(
      variables === undefined ? ['GetProfile'] : ['GetProfile', variables],
      useFetchData<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument).bind(null, variables),
      options
    );
export const UpdateProfileDocument = `
    mutation UpdateProfile($fullName: String!) {
  updateProfile(fullName: $fullName)
}
    `;
export const useUpdateProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>) =>
    useMutation<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>(
      ['UpdateProfile'],
      useFetchData<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument),
      options
    );